
import React, { useEffect, useRef, useState } from "react";
import  '../css/Timer.css'





const Timer = () => {

    
 
  const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');

    let interval = useRef();

    const startTimer = () => {
      
        const countDownDate = new Date("Jun 11, 2023 15:00:00").getTime(); 
        
        interval = setInterval(() =>{
            const now = new Date().getTime();
            const distance = countDownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
            if (distance < 0) {
                //stop our timer
                clearInterval(interval.current);
            } else {
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }

        }, 1000);
    };

    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    } );
    return (
      <section className="timer-container">
          <section className="timer">
              <div>
                <section>
                    <p>{timerDays}</p>
                    <p><small>zile</small></p>
                </section> 
                <span>:</span> 
                <section>
                    <p>{timerHours}</p>
                    <p><small>ore</small></p>
                </section> 
                <span>:</span> 
                <section>
                    <p>{timerMinutes}</p>
                    <p><small>minute</small></p>
                </section> 
                <span>:</span> 
                <section>
                    <p>{timerSeconds}</p>
                    <p><small>secunde</small></p>
                </section> 
              </div>

          </section>
      </section>
    );
    
};

export default Timer;