import restaurant from "./images/murmur.jpg";
import biserica from "./images/bis.jpg";
import img_card from "./images/n.jpg";
import imgheadermb from "./images/f1.avif";
import imgheader from "./images/f1.avif";
import imgheadermiini from "./images/bg2.jpg";
import imgheadermiinimb from "./images/bg2.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Arsenie și Miranda",
        familia: "fam. Purici",
        logo: logo,
        tata: "",
        mama: "Elena",
        data: "11 Iunie 2023",
        data_confirmare: "1 iunie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "lenagotuleac@gmail.com", 
       tel: "+37360084817",
       phone: "tel:+37360084817",
       viber: "viber://chat?number=%2B37360084817",
       whatsapp: "https://wa.me/+37360084817",
       messenger: "https://www.messenger.com/t/lena.gotuleac",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Sfânta Parascheva",
            data: "11 iunie 2023, duminică, ora 13:00",
            adress: "Satul Ratuș, R. Moldova",
            harta: "https://goo.gl/maps/tQGznE1XnfKRKKRa7",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Murmur de Izvor",
            data: "11 iunie 2023, duminică, ora 15:00",
            adress: "Drăsliceni - Ratuș, R. Moldova",
            harta: "https://goo.gl/maps/riAc2LMgCiSsvGCf7",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, sunt pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;