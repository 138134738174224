
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import gemeni from '../images/g.jpg'
import "../css/ImgInvitatie.css"
import background from "../images/bg2.jpg"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4"  >
                <div className="card p-0 overflow-hidden h-100 shadow">
                  <h4 className="pt-5">Arsenie și Miranda</h4>  
                    <h3>VĂ INVITĂ</h3>    
                <div className="blog-img text-center pt-5">
                
              <img className=" img-fluid" src={gemeni} />
              
              
            </div>
            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                            <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                            </svg>
                            <br />
                        </h5>
                    <div class="card-body">
                        <h5>
                            Suntem foarte norocoși,<br/>
                            Avem 2 copii frumoși,<br/>
                            Suntem foarte fericiți,<br/>
                            Avem 2 copii cuminți.<br/>
                            În curând îi botezăm <br/>
                            Și pe voi vă invităm <br/>
                            Să petrecem împreună <br/>
                            Cu joc și cu voie bună.<br/>
                           
                        Vă așteptăm cu mare drag <br/>
                        Pe data de  
                        </h5>
                       <h4>11 iunie 2023</h4>
                       
                        <h5>Încreștinarea va avea loc la Biserica</h5>
                        <h4>"Sfânta Parascheva din s. Ratuș",</h4>
                        <h5>la ora 13:00, 
                            </h5>
                            <h5>
                             Apoi mergem să petrecem la
                            </h5>
                        <h4>Restaurantul „Murmur de Izvor”</h4>
                        <h5> începând cu ora 15:00.</h5>
                           
                   
                        
                        
                  
                     
                       
                      


                        {/** <ImgInvitatie />*/}

                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>
                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>
                    </div>




                </div>
            </div>
        </section>

    )
}

export default CardInvitatie;